<template>
    <div class="shop_main noMenu" style="background: #FFF">
        <div class="shop_order_main">
            <userMenu type="11" />

            <div class="shop_order_list" v-loading="isRequestLoading">
                <div class="shop_order_detail_back">
                    <el-button type="primary" @click="$router.go(-1)">返回订单列表</el-button>
                </div>

                <div class="shop_order_detail_info">
                    <span class="no">{{dataInfo.createdAt}}</span>
                    <span class="no ml16">订单编号：{{dataInfo.detailCode}}</span>
                    <a href="javascript:void(0)" @click="isPopupLog = true">更多</a>
                </div>

                <div class="shop_order_detail_desc">
                    <div class="item">
                        <span>买家账号：{{dataInfo.userAccount}}</span>
                        <span>收货人：{{dataInfo.channel === 2 ? '店铺自提' : dataInfo.userName}}</span>
                        <span>收件人电话：{{dataInfo.channel === 2 ? '--' : dataInfo.userPhone}}</span>
                        <span>收货地址：{{dataInfo.channel === 2 ? '--' : dataInfo.userProvince + dataInfo.userCity + dataInfo.userArea + dataInfo.userAddress}}</span>
                        <span>买家留言：{{dataInfo.remark}}</span>
                    </div>

                    <div class="item">
                        <span>商家：{{dataInfo.storeName}}</span>
<!--                        <span v-if="dataInfo.warehouseName">仓库：{{dataInfo.warehouseName}}</span>-->
<!--                        <span>商品/货号：{{dataInfo.storeName}}</span>-->
                        <span>发货方式：<template v-if="dataInfo.sendType === 1">线下发货</template><template v-if="dataInfo.sendType === 2">仓储发货</template><template class="text" v-if="dataInfo.sendType === 3">货权交割</template></span>
                        <span>配送方式：{{dataInfo.deliveryType === 1 ? '物流配送' : '店铺自提'}}</span>
                        <span>商品数量：{{dataInfo.num}}</span>
                        <span v-if="dataInfo.receiptCode">单据号：{{dataInfo.receiptCode}}</span>
                    </div>

                    <div class="item" style="align-items: flex-end">
                        <span>订单状态：
                            <template v-if="dataInfo.status === 1">待付款</template>
                            <template v-if="dataInfo.status === 2">待发货</template>
                            <template v-if="dataInfo.status === 3">已发货</template>
                            <template v-if="dataInfo.status === 4">已完成</template>
                            <template v-if="dataInfo.status === 5">已取消</template>
                        </span>

                        <span v-if="dataInfo.logisticsName">物流信息：{{dataInfo.logisticsName}} | {{dataInfo.logisticsCode}}</span>
                        <a href="javascript:void(0)" v-if="logisticsUrl.length > 0" @click="dataInfo.logisticsType === 1 ? toShowImage(logisticsUrl) : toShowFile('物流凭证查询', logisticsUrl)">(物流凭证查询)</a>

                        <span v-if="dataInfo.contractCode">合同：{{dataInfo.contractCode}}</span>
                        <a href="javascript:void(0)" v-if="contractUrl.length > 0" @click="dataInfo.contractType === 1 ? toShowImage(contractUrl) : toShowFile('合同查询', contractUrl)">(合同查询)</a>

                        <span>支付方式：
                            <span class="text" v-if="dataInfo.payWay === 1">直接打款</span>
                            <span class="text" v-if="dataInfo.payWay === 2">线下支付</span>
                            <span class="text" v-if="dataInfo.payWay === 3">担保支付</span>
                        </span>
                        <span v-if="dataInfo.payWay === 1 || dataInfo.payWay === 3">支付类型：{{getPayTypeName(dataInfo.payType)}}</span>
                        <span v-if="dataInfo.payWay === 1 || dataInfo.payWay === 3">支付单号：{{dataInfo.payCode}}</span>
                    </div>
                </div>


                <div class="shop_order_data mb0">
                    <div class="shop_order_table">
                        <div class="table_header">
                            <div class="item" style="flex: 3; text-align: center"><span>商品</span></div>
                            <div class="item" style="flex: 1; text-align: center"><span>单价</span></div>
                            <div class="item" style="flex: 1; text-align: center"><span>数量</span></div>
                        </div>

                        <div class="table_list">
                            <div class="table_item">
                                <div class="table_item_body">
                                    <div class="item" style="flex: 5">
                                        <div class="goods_list" v-for="(item, index) in dataInfo.goodsInfo" :key="index">
                                            <div class="goods_list_item" style="flex: 3">
                                                <div class="goods_item">
                                                    <el-image :src="imageURL + item.goodsImageUrl" class="image" fit="contain" @click="$router.push({path: '/shop/detail', query: {id: item.goodsId}})"></el-image>
                                                    <div class="goods_item_info">
                                                        <div class="name" @click="$router.push({path: '/shop/detail', query: {id: item.goodsId}})">{{item.goodsName}}</div>
                                                        <div class="text">
                                                            <span class="mr16" v-for="(sku, j) in item.sku" :key="j">{{sku.skuKey}}：{{sku.skuValue}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="goods_list_item" style="flex: 1; text-align: center">
                                                <span class="desc">￥{{item.price}}</span>
                                            </div>
                                            <div class="goods_list_item" style="flex: 1; text-align: center">
                                                <span class="desc">×{{item.num}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

<!--                <div class="shop_order_item mt24" v-if="dataInfo.status === 3 || dataInfo.status === 4">-->
<!--                    <h1 class="tableTitle">物流信息</h1>-->
<!--                    <el-table :data="list" class="order_table mt14">-->
<!--                        <el-table-column prop="name" label="物流公司" min-width="160px"></el-table-column>-->
<!--                        <el-table-column prop="name" label="物流单号" min-width="160px"></el-table-column>-->
<!--                        <el-table-column prop="name" label="商品/数量" min-width="240px"></el-table-column>-->
<!--                        <el-table-column prop="name" label="发货时间" min-width="160px"></el-table-column>-->

<!--                        <el-table-column prop="address" label="操作" align="center" width="80">-->
<!--                            <template>-->
<!--                                <el-button type="text">详情</el-button>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
<!--                    </el-table>-->
<!--                </div>-->

                <div class="shop_order_item mt24" v-if="dataInfo.payWay === 1 && (dataInfo.status === 2 || dataInfo.status === 3 || dataInfo.status === 4)">
                    <h1 class="tableTitle">支付单</h1>
                    <el-table :data="payList" class="order_table mt14">
                        <el-table-column prop="payType" label="支付类型" min-width="120px"></el-table-column>
                        <el-table-column prop="paySn" label="支付单号" min-width="120px"></el-table-column>
                        <el-table-column prop="amount" label="支付金额" min-width="120px"></el-table-column>
                        <el-table-column prop="payTime" label="支付时间" min-width="160px"></el-table-column>
                        <el-table-column prop="name" label="支付状态" min-width="120px">
                            <template slot-scope="scope">
                                <div class="status green" v-if="scope.row.status === -2">短信发送成功</div>
                                <div class="status red" v-if="scope.row.status === -1">失败</div>
                                <div class="status info" v-if="scope.row.status === 0">初始化</div>
                                <div class="status green" v-if="scope.row.status === 1">成功</div>
                                <div class="status yellow" v-if="scope.row.status === 2">处理中</div>
                            </template>
                        </el-table-column>
<!--                        <el-table-column prop="name" label="结算状态" min-width="120px"></el-table-column>-->
<!--                        <el-table-column prop="name" label="退款金额" min-width="160px"></el-table-column>-->

<!--                        <el-table-column prop="address" label="操作" align="center" width="160">-->
<!--                            <template>-->
<!--                                <el-button type="text" @click="isPopupRefund = true">确认结算</el-button>-->
<!--                                <el-button type="text" @click="isPopupRefundCheck = true">延长结算时间</el-button>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
                    </el-table>
                </div>

                <div class="shop_order_detail_footer">
<!--                    <span v-if="dataInfo.status === 2 || dataInfo.status === 3 || dataInfo.status === 4">全额付款-->
<!--                        <template v-if="dataInfo.channel === 1">-->
<!--                            <a href="javascript:void(0)" style="position: relative" @click="toShowImage(url)">（付款凭证查询）</a>-->
<!--                            <a href="javascript:void(0)" v-if="invoiceUrl.length > 0" style="position: relative" @click="dataInfo.invoiceType === 1 ? toShowImage(invoiceUrl) : toShowFile('发票查询', invoiceUrl)">（发票查询）</a>-->
<!--                        </template>-->
<!--                    </span>-->
<!--                    <span>订单应付金额： ¥ {{dataInfo.price}}</span>-->

                    <div class="item">
                        <span class="text" v-if="dataInfo.status === 2 || dataInfo.status === 3 || dataInfo.status === 4">全额付款</span>
                        <template v-if="dataInfo.channel === 1">
                            <a href="javascript:void(0)" class="link" @click="toShowImage(url)" v-if="Number(dataInfo.payWay) === 2">（付款凭证查询）</a>
                            <a href="javascript:void(0)" class="link" v-if="invoiceUrl.length > 0" @click="dataInfo.invoiceType === 1 ? toShowImage(invoiceUrl) : toShowFile('发票查询', invoiceUrl)">（发票查询）</a>
                        </template>
                    </div>

                    <div class="item">
                        <span class="text">订单应付金额： ¥ {{dataInfo.price}}</span>

                        <a href="javascript:void(0)" class="btn" v-if="dataInfo.status === 1 && dataInfo.payWay === 1" @click="$router.push({path: '/shop/order/pay', query: {id: dataInfo.id}})">付 款</a>
                        <a href="javascript:void(0)" class="btn" v-if="dataInfo.status === 1 && dataInfo.payWay === 2" @click="$router.push({path: '/shop/order/upload', query: {id: dataInfo.id}})">付款凭证上传</a>
                    </div>
                </div>

            </div>
        </div>

        <imageViewer v-if="showViewer" :on-close="closeViewer" :url-list="showImageList" />
        <filePopup :isFilePopup="isFilePopup" :fileTitle="fileTitle" :fileList="fileList" @closePopup="isFilePopup = false" />

        <el-dialog class="popup_dialog" title="订单日志" :visible.sync="isPopupLog" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="800px">
            <el-table ref="goodsFormatData" :data="orderLog" style="width: 100%" class="addGoodsChangeFormatTable">
                <el-table-column prop="logTime" label="操作时间"></el-table-column>
                <el-table-column prop="status" label="操作内容"></el-table-column>
            </el-table>

            <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange" />

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="isPopupLog = false" size="small">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
  import {getOrdersInfo, getLogs, getOrdersPay} from '../../../api/shop/orders'
  import {imageURL} from "../../../utils/config";
  const userMenu = () => import('../../../components/layout/shop/userMenu')
  const filePopup = () => import('../../../components/filePopup')
  const pagination = () => import('../../../components/pagination/shop')
  const imageViewer = () => import('../../../components/imageViewer')
  export default {
    name: "shopOrderDetail",
    data () {
      return {
        isRequestLoading: false,
        dataInfo: {},
        imageURL: imageURL,
        url: [],
        invoiceUrl: [],
        logisticsUrl: [],
        contractUrl: [],
        showImageList: [],
        isFilePopup: false,
        fileTitle: '',
        fileList: [],
        isPopupLog: false,
        orderLog: [],
        listQuery: {
          page: 1,
          pageSize: 5
        },
        pages: {
          total: 0,
          pages: 0
        },
        listData: [],
        showViewer: false,
        prevOverflow: '',
        payList: []
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getInfo();
      this.getOrdersPayInfo();
      this.getLogs()
    },
    methods: {
      getInfo () {
        this.isRequestLoading = true
        getOrdersInfo(this.$route.query.id).then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.dataInfo = res.data

            let url = []
            if (res.data.url !== null) {
              res.data.url.forEach(item => {
                url.push(this.imageURL + item)
              })
            }
            this.url = url

            let invoiceUrl = []
            if (res.data.invoiceType === 1 && res.data.invoiceUrl) {
              res.data.invoiceUrl.forEach(item => {
                invoiceUrl.push(this.imageURL + item.url)
              })
              this.invoiceUrl = invoiceUrl
            }
            if (res.data.invoiceType === 2 && res.data.invoiceUrl) {
              this.invoiceUrl = res.data.invoiceUrl
            }

            let logisticsUrl = []
            if (res.data.logisticsType === 1 && res.data.logisticsUrl) {
              JSON.parse(res.data.logisticsUrl).forEach(item => {
                logisticsUrl.push(this.imageURL + item.url)
              })
              this.logisticsUrl = logisticsUrl
            }
            if (res.data.logisticsType === 2 && res.data.logisticsUrl) {
              this.logisticsUrl = JSON.parse(res.data.logisticsUrl)
            }

            let contractUrl = []
            if (res.data.contractType === 1 && res.data.contractUrl) {
              res.data.contractUrl.forEach(item => {
                contractUrl.push(this.imageURL + item.url)
              })
              this.contractUrl = contractUrl
            }
            if (res.data.contractType === 2 && res.data.contractUrl) {
              this.contractUrl = res.data.contractUrl
            }
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      getOrdersPayInfo () {
        getOrdersPay(this.$route.query.id).then(res => {
          if (res.code === 0) {
            this.payList = res.data.payList
          }
        })
      },
      toShowImage(list) {
        this.showImageList = list
        this.prevOverflow = document.body.style.overflow;
        document.body.style.overflow = 'hidden';
        this.showViewer = true
      },
      closeViewer () {
        document.body.style.overflow = this.prevOverflow;
        this.showViewer = false
      },
      toShowFile (title, list) {
        this.isFilePopup = true
        this.fileTitle = title
        this.fileList = list
      },
      getLogs () {
        getLogs({
          detailId: this.$route.query.id,
          page: this.listQuery.page,
          pageSize: this.listQuery.pageSize
        }).then(res => {
          if (res.code === 0) {
            this.orderLog = res.data.list
            this.pages.total = res.data.total
            this.pages.pages = res.data.pages
          }
        })
      },
      pageChange (e) {
        this.listQuery.page = e.current
        this.listQuery.pageSize = e.size
        this.getLogs()
      },
      getPayTypeName (payType) {
        let name = '';
        let type = Number(payType);
        switch (type) {
          case 1:
            name = '个人网银支付';
            break;
          case 2:
            name = '企业网银支付';
            break;
          case 3:
            name = '快捷支付';
            break;
          case 4:
            name = '微信app支付';
            break;
          case 5:
            name = '支付宝app支付';
            break;
          case 6:
            name = '微信扫码支付';
            break;
          case 9:
            name = '支付宝扫码支付';
            break;
        }
        return name;
      },
    },
    components: {
      userMenu, filePopup, pagination, imageViewer
    }
  }
</script>
